<template>
  <footer class="border-top mt-auto p-3">
    <div class="container">
      <p class="text-body-sm !tw-mb-0 !tw-text-center">
        <span>Copyright &copy; {{ new Date().getFullYear() }} YAZIO. All rights reserved.&nbsp;</span>
        <a href="https://www.yazio.com/contact" target="_blank">
          {{ $t("app.general.label.contact") }}
        </a>
      </p>
    </div>
  </footer>
</template>
