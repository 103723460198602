<script lang="ts" setup>
const { assetBaseUrl } = useRuntimeConfig().public;
</script>

<template>
  <header>
    <nav class="navbar">
      <div class="container !tw-flex !tw-items-center !tw-justify-between">
        <img
          :src="assetBaseUrl + '/frontend/images/yazio-logo.svg'"
          class="navbar-logo"
          type="image/svg+xml"
          alt="YAZIO Logo"
        />
        <slot />
        <!--        <LangSwitcher />-->
      </div>
    </nav>
  </header>
</template>
